import { Box } from "@mui/material";
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersRoles, roleSelector } from '../../store/slices/rolesSlice';
import {
	MaterialReactTable,
	useMaterialReactTable,
	type MRT_ColumnDef, //if using TypeScript (optional, but recommended)
} from 'material-react-table';
import EditModal from "./EditModal";
import { AppDispatch } from "../../store/store";

interface ListUserRole {
	id: number;
	email: string;
	username: string;
	role: string;
}

export default function Roles() {

	const dispatch = useDispatch<AppDispatch>();
	const { users: data, roles } = useSelector(roleSelector);

	useEffect(()=>{
		dispatch(getUsersRoles())
	}, [dispatch])

	const columns = useMemo<MRT_ColumnDef<ListUserRole>[]>(
		() => [
			{
				accessorKey: 'email',
				header: 'Email',
			},
			{
				accessorKey: 'username',
				header: 'Usuario',
			},
			{
				accessorKey: 'role',
				header: 'Rol',
			},
			{
				id: 'actions',
				header: 'Asignar',
				enableColumnActions: false,
				enableColumnFilter: false,
				Cell: ({ row }) => (
					<Box sx={{ display: 'flex', ml: -1 }}>
						<EditModal obj={row.original} roles={roles}/>
					</Box>
				),
			},
		],
		[roles],
	);

	const table = useMaterialReactTable({
		columns,
		data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnActions: false,
    enableColumnFilters: false,
    // enablePagination: false,
    enableSorting: true,
		enableFullScreenToggle:false,
		enableDensityToggle: false,
		initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
		// initialState:{ density: 'compact' },
		enableHiding: false,		
		muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
	});


	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<h3>Mantenedor de Roles</h3>
			</Box>
			<MaterialReactTable table={table} />
		</>
	)
}