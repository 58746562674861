import React, { useContext, useEffect, useState, useMemo } from 'react';
import CalendarContext from '../ScheduleDashboard/CalendarContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { TaskInstance } from '../../types/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { updateTaskInstanceAsync } from '../../store/slices/taskInstanceSlice';
import LoadingModal from '../modals/LoadingModal/LoadingModal';
import TaskDetails from './TaskDetails';
import TaskStatus from './TaskStatus';
import ApprovedStatus from './ApprovedStatus';
import FileUploadModal from './FileUploadModal';
import TaskMessages from './TaskMessages';
import { handleFileChange, handleUploadFile, handleSave } from './TaskHandlers';

const TaskBar: React.FC = () => {
  const { selectedEvent } = useContext(CalendarContext);
  const dispatch = useDispatch<AppDispatch>();
  const [editedTaskInstance, setEditedTaskInstance] =
    useState<TaskInstance | null>(selectedEvent?.details ?? null);
  const [originalTaskInstance, setOriginalTaskInstance] =
    useState<TaskInstance | null>(selectedEvent?.details ?? null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogModalOpen, setDialogModalOpen] = useState<boolean>(false);
  const [dueDateValue, setDueDateValue] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const user = useSelector((state: RootState) => state.user.user);
 
  const userRoles: string[] = useMemo(
    () => (user?.roles || []),
    [user]
  );

  useEffect(() => {
    if (selectedEvent?.details) {
      setEditedTaskInstance(selectedEvent.details);
      setOriginalTaskInstance(selectedEvent.details);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (editedTaskInstance?.dueDate) {
      const date = new Date(editedTaskInstance.dueDate);
      const formattedDate = date.toISOString().split('T')[0];
      setDueDateValue(formattedDate);
    }
  }, [editedTaskInstance]);

  if (!selectedEvent) {
    return <div>No hay evento seleccionado.</div>;
  }

  if (!selectedEvent.details) {
    return <div>No hay detalles para mostrar.</div>;
  }

  const { details } = selectedEvent;
  const task = details.task;
  const regulation = task?.regulation;

  if (!task || !regulation) {
    return <div>No hay detalles para mostrar.</div>;
  }

  const isTaskFinalized =
    editedTaskInstance?.status === 'Finalizado' &&
    editedTaskInstance?.approvedStatus !== 'pendiente';

  const isSaveDisabled =
    JSON.stringify(editedTaskInstance) ===
      JSON.stringify(originalTaskInstance) || isTaskFinalized;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenDialogModal = () => setDialogModalOpen(true);
  const handleCloseDialogModal = () => setDialogModalOpen(false);

  const handleApprove = async () => {
    if (!editedTaskInstance || !isTaskFinalized) return;
    const updatedTaskInstance = {
      id: editedTaskInstance.id,
      dueDate: editedTaskInstance.dueDate,
      status: 'Finalizado',
      completionDate: editedTaskInstance.completionDate,
      approvedStatus: 'aprobada',
      documents: editedTaskInstance.documents,
      task: editedTaskInstance.task,
      taskId: editedTaskInstance.taskId,
    };
    setEditedTaskInstance(updatedTaskInstance);

    handleSave(
      updatedTaskInstance,
      setLoading,
      dispatch,
      updateTaskInstanceAsync,
      setOriginalTaskInstance,
      user.id
    );
  };

  return (
    <div>
      <h4>
        {regulation?.code} - T{task?.number}
      </h4>
      <TaskDetails
        task={task}
        regulation={regulation}
        editedTaskInstance={editedTaskInstance}
        setLoading={setLoading}
        setEditedTaskInstance={setEditedTaskInstance}
        isDisabled={isTaskFinalized}
      />
      {(!userRoles.includes('supervisor')) && 
        <Button
          variant="outlined"
          onClick={handleOpenDialogModal}
          style={{ marginBottom: '20px' }}
        >
          Diálogos de Trabajo
        </Button>
      }

      <TaskStatus
        editedTaskInstance={editedTaskInstance}
        dueDateValue={dueDateValue}
        setDueDateValue={setDueDateValue}
        setEditedTaskInstance={setEditedTaskInstance}
        isDisabled={userRoles.includes('supervisor') ? true: isTaskFinalized}
      />
      <ApprovedStatus
        approvedStatus={editedTaskInstance?.approvedStatus}
        onApprove={handleApprove}
        editedTaskInstance={editedTaskInstance}
      />
      {(!userRoles.includes('supervisor')) && 
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '20px' }}
            onClick={handleOpenModal}
            disabled={isTaskFinalized}
          >
            Subir Documento
            <AttachFileIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave(
                editedTaskInstance,
                setLoading,
                dispatch,
                updateTaskInstanceAsync,
                setOriginalTaskInstance,
                user.id
              );
            }}
            style={{ marginTop: '20px' }}
            disabled={isSaveDisabled}
          >
            Guardar
          </Button>
        </Box>
      }

      <FileUploadModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        handleFileChange={(e) => handleFileChange(e, setSelectedFile)}
        handleUploadFile={() =>
          handleUploadFile(
            editedTaskInstance,
            selectedFile,
            setLoading,
            setEditedTaskInstance,
            setSelectedFile,
            handleCloseModal
          )
        }
        isDisabled={isTaskFinalized}
      />
      <LoadingModal open={loading} />

      <Dialog
        open={dialogModalOpen}
        onClose={handleCloseDialogModal}
        aria-labelledby="dialog-modal-title"
        aria-describedby="dialog-modal-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="dialog-modal-title">Diálogos de Trabajo</DialogTitle>
        <DialogContent dividers sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Box id="dialog-modal-description">
            {editedTaskInstance?.id && (
              <TaskMessages taskInstanceId={editedTaskInstance.id} />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogModal}
            color="secondary"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TaskBar;
