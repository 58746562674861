import axios from './axiosConfig';
import { API_BASE_URL } from './axiosConfig';

export const fetchUserRoles = async () => {
	try {
		const response = await axios.get(`${API_BASE_URL}/roles/users`, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error('Error fetching roles users:', error);
		throw error;
	}
}

export const updateUserRole = async (data) => {
	try {
		const response = await axios.post(`${API_BASE_URL}/roles/users`, data, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error('Error update user role:', error);
		throw error;
	}
}