import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserRole } from '../../store/slices/rolesSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditModal({ obj, roles }) {

  const dispatch = useDispatch();

  const { username } = obj;
  const [roleSelected, setRoleSelected] = useState(0)
  const [showMsg, setShowMsg] = useState(false)

  useEffect(() => {
    setRole(roles.find(x => x.name === obj.role)?.id || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    // console.log(event.target.value)
    setRole(event.target.value)
  };

  const setRole = (id) => setRoleSelected(id);

  const saveUserRole = () => {
    if (roleSelected !== 0){
      setShowMsg(false)
      dispatch(setUserRole({ userId: obj.id, roleId: roleSelected }))
    }else{
      setShowMsg(true)
    }

    
  }

  return (
    <div>
      <Tooltip title="Asignar">
        <IconButton onClick={handleOpen}>
          <EditIcon color="warning" />
        </IconButton>
      </Tooltip>


      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style} display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography id="modal-username" variant="h6" component="h2">
            {username}
          </Typography>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="roles">Rol:</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={roleSelected || ''}
              onChange={handleChange}
              label="Age"
            >
              {/* <MenuItem value={0}>sin asignar</MenuItem>*/}
              {roles.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>

          <Button variant="contained" color="primary" onClick={saveUserRole}>
            Grabar
          </Button>

          {showMsg &&
          <Box style={{ position: 'absolute', left: 8, bottom: 8 }}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            >
            <Typography color={'red'}>
              Selecciona un rol para grabar
            </Typography>
          </Box>
          }

        </Box>
      </Modal>
    </div>
  );
}