import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { List } from '@mui/material';
import { MainListItems, SecondaryListItems } from './listItems';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../store/store';
import { getRegulations } from '../../store/slices/regulationSlice';
import { fetchUser, setCredentials } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import AccountMenu from '../AccountMenu/AccountMenu';
import NotificationsMenu from '../NotficationMenu/NotificationMenu';
import { verifyToken } from '../../api/api';

const drawerWidth: number = 200;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'rgb(73, 97, 117)',
  boxShadow: `none`,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    backgroundColor: 'rgb(73, 97, 117)',
    color: 'whitesmoke',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const userName = useSelector((state: any) => state.user?.user?.username);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRegulations());
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(()=>{
    const interval = setInterval(() => {
      const token = sessionStorage.getItem('access_token');
      if (token) {
        verifyToken(token).then((isValid) => {
          if (!isValid) {
            sessionStorage.removeItem('access_token');
            dispatch(setCredentials({ isLoggedIn: false }));
            navigate('/login');
          }
        });
      }
    }, 1 * 60 * 5000); // cada minuto

    return () => clearInterval(interval);
  }, [dispatch, navigate])


  const notifications = useSelector(
    (state: any) => state.notifications.notifications
  );

  console.log(notifications);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} sx={{ color: 'whitesmoke' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '20px',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            sx={{
              height: 50,
            }}
            alt="Fynsa"
            src={'Logo_Fynsa_blanco.png'}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              mb: 4,
              justifyContent: 'center',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              color="inherit"
              noWrap
              sx={{ position: 'absolute', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            ></Typography>
          </Box>
          <IconButton color="inherit">
            <NotificationsMenu />
          </IconButton>
          <IconButton color="inherit">
            <AccountMenu userName={userName} />
          </IconButton>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <List component="nav">
          <MainListItems />
          {/* <Divider sx={{ my: 1 }}  */}
          <SecondaryListItems />
        </List>
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: `calc(100% - ${drawerWidth}px)`,
          pt: 8,
        }}
      >
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {children}
        </Box>
        <Box
          component="footer"
          sx={{
            textAlign: 'center',
            fontSize: '0.9rem',
          }}
        >
          Compliance Fynsa Versión 0.1
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
