import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUserRoles, updateUserRole } from '../../api/rolesApi';


const initialState = {
	users: [],
	roles: [],
	loading: false,
	loadingSave: false
}

export const getUsersRoles = createAsyncThunk(
	'roles/getUsersRoles',
	async () => {
		const usersRoles = await fetchUserRoles();		
		return usersRoles;
	}
)

export const setUserRole = createAsyncThunk(
	'roles/setUserRole',
	async (data, thunkAPI) => {
		const userRole = await updateUserRole(data)
		thunkAPI.dispatch(getUsersRoles())
		return userRole
	}
)

const roleSlice = createSlice({
	name: "roleSlice",
	initialState,
	extraReducers: builder => {
		builder
			.addCase(getUsersRoles.pending, (state) => {
				state.users = []
				state.roles = []
				state.loading = true
			})
			.addCase(getUsersRoles.fulfilled, (state, action) => {
				state.users = action.payload.users;
				state.roles = action.payload.roles;
				state.loading = false
			})
			.addCase(getUsersRoles.rejected, (state, action) => {
				state.users = []
				state.roles = []
				state.loading = false
			})
			.addCase(setUserRole.pending, (state) => {
				state.loadingSave = true
			})
			.addCase(setUserRole.fulfilled, (state, action) => {
				state.loadingSave = false
			})
			.addCase(setUserRole.rejected, (state, action) => {
				state.loadingSave = false
			})
	}
})

export const roleSelector = state => state.roles;
export default roleSlice.reducer;