import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import GroupIcon from '@mui/icons-material/Group';

export const MainListItems = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const userRoles: string[] = user?.roles || [];

  return (
    <React.Fragment>
      {/* {!userRoles.includes('visita') && ( */}
        <ListItemButton onClick={() => navigate('/')}>
          <ListItemIcon>
            <CalendarMonthIcon sx={{ color: 'whitesmoke' }} />
          </ListItemIcon>
          <ListItemText primary="Calendario" />
        </ListItemButton>
      {/* )} */}

      <ListItemButton onClick={() => navigate('/dashboard')}>
        <ListItemIcon>
          <DashboardIcon sx={{ color: 'whitesmoke' }} />
        </ListItemIcon>
        <ListItemText primary="Normativas" />
      </ListItemButton>

      {userRoles.includes('admin') && (
      <ListItemButton onClick={() => navigate('/roles')}>
        <ListItemIcon>
          <GroupIcon sx={{ color: 'whitesmoke' }} />
        </ListItemIcon>
        <ListItemText primary="Roles" />
      </ListItemButton>
      )}

    </React.Fragment>
  );
};

export const SecondaryListItems = () => (
  <React.Fragment>
    {/* <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon sx={{ color: 'whitesmoke' }} />
      </ListItemIcon>
      <ListItemText primary="Reportes" />
    </ListItemButton> */}
    {/* Aquí irían otros botones secundarios */}
  </React.Fragment>
);
