import { configureStore } from '@reduxjs/toolkit';
import regulationReducer from './slices/regulationSlice';
import userSlice from './slices/userSlice';
import taskInstanceSlice from './slices/taskInstanceSlice';
import notificationsSlice from './slices/notificationsSlice';
import roleSlice from './slices/rolesSlice';

const store = configureStore({
  reducer: {
    regulation: regulationReducer,
    user: userSlice,
    taskInstances: taskInstanceSlice,
    notifications: notificationsSlice,
    roles: roleSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
